<template>
    <News />
</template>

<script>
import News from '@apps/News'
import pageMeta from '@/mixins/pageMeta'
export default {
    mixins: [pageMeta],
    components: {
        News
    }
}
</script>